<template>
  <v-dialog persistent v-model="showStreamEngineVersionDialog" width="700">
    <v-card :loading="loading">
      <v-form
        lazy-validation
        v-model="form"
        ref="newStreamEngineVersionForm"
        :disable="loading"
      >
        <v-card-title>
          <span class="text-h6">New Stream Engine Version</span>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span>Select Architecture</span>
              <v-radio-group class="mt-0" row v-model="archType">
                <v-radio label="Jetson" value="arm64"></v-radio>
                <v-radio label="Workstation" value="x64"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="6" v-if="archType === 'x64'">
              <v-select
                v-model="streamEngineVersionPayload.os_ids"
                :items="supportedOperatingSystems"
                :rules="[rules.required]"
                label="Operating System"
                :item-text="(item) => item.name + '  ' + item.version"
                item-value="id"
                outlined
                small-chips
                multiple
                deletable-chips
                chips
                hide-details="auto"
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    item.name + ' ' + item.version
                  }}</v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col v-if="archType === 'arm64'" cols="6">
              <v-select
                v-model="streamEngineVersionPayload.jetpack_ids"
                hide-details="auto"
                label="Jetpack Versions"
                outlined
                :items="jetpackVersionList"
                :rules="[rules.required]"
                :item-text="
                  (item) =>
                    'L4T: ' +
                    item.l4t +
                    ' / ' +
                    'Jetpack Version: ' +
                    item.version
                "
                item-value="id"
                small-chips
                multiple
                deletable-chips
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    'Jetpack: ' + item.version
                  }}</v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ 'Jetpack: ' + item.version }}</span>
                          <v-spacer></v-spacer>
                          <v-chip small color="primary">{{
                            'L4T: ' + item.l4t
                          }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="streamEngineVersionPayload.version"
                :rules="[rules.required]"
                auto-select-first
                label="*Stream Engine Version"
                color="deep-purple"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="streamEngineVersionPayload.shasum"
                :rules="[rules.required]"
                color="deep-purple"
                label="*Shasum"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="archType === 'x64'">
              <v-autocomplete
                v-model="streamEngineVersionPayload.min_x86_client_version_id"
                :items="x86CordatusClients"
                item-text="version"
                item-value="id"
                auto-select-first
                :rules="[rules.required]"
                label="*Minimum Client(x86) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="archType === 'arm64'">
              <v-autocomplete
                v-model="
                  streamEngineVersionPayload.min_jetson_client_version_id
                "
                :items="jetsonCordatusClients"
                item-text="version"
                item-value="id"
                auto-select-first
                :rules="[rules.required]"
                label="*Minimum Client(arm64) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="newStreamEngineVersion()"
              :disabled="!form || loading"
              color="primary"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import { apiService } from '../../../services/api.service'

export default {
  computed: {
    ...mapGetters([
      'clientVersionList',
      'clientVersionTableLoading',
      'x86CordatusClients',
      'jetsonCordatusClients',
      'supportedOperatingSystems',
      'jetpackVersionList'
    ])
  },
  props: {
    showStreamEngineVersionDialog: { type: Boolean, default: false }
  },

  data: () => ({
    loading: false,
    form: true,
    archType: 'arm64',
    streamEngineVersionPayload: {
      version: '',
      arch: '',
      shasum: '',
      jetpack_ids: [],
      os_ids: [],
      min_x86_client_version_id: null,
      min_jetson_client_version_id: null
    },
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),

  created () {
    this.getVersionList()
  },

  methods: {
    newStreamEngineVersion () {
      if (this.$refs.newStreamEngineVersionForm.validate()) {
        this.streamEngineVersionPayload.arch = this.archType
        this.loading = true
        apiService
          .post('admin/stream_engine_version', this.streamEngineVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New stream engine version added successfully'
            })
            this.$emit('fetchStreamEngineVersions')
            this.$store.dispatch('setNonNotifiedStreamEngineVersion', res.data.new_version.id)
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('Error occurred while create new stream engine: ', err.response)
            Swal.fire({
              icon: 'error',
              title: 'Error occurred while create new stream engine!',
              text: err.response.data.message
            })
          })
      }
    },

    getVersionList () {
      if (this.clientVersionList.length === 0) {
        this.$store.dispatch('getClientVersion')
      }

      if (this.supportedOperatingSystems.length === 0) {
        this.$store.dispatch('getSupportedOperatingSystem')
      }

      if (this.jetpackVersionList.length === 0) {
        this.$store.dispatch('getJetpackVersionList')
      }
    },

    close () {
      this.$emit('closeNewClientVersionDialog')
    }
  }
}
</script>

  <style>
</style>
