<template>
  <v-dialog persistent v-model="showEditStreamEngineVersionDialog" width="700">
    <v-card :loading="loading">
      <v-form
        lazy-validation
        v-model="form"
        ref="newStreamEngineVersionForm"
        :disable="loading"
      >
        <v-card-title>
          <span class="text-h6 mr-2">Edit {{ streamEngine.version }}</span>
          <v-chip color="primary">{{ streamEngine.arch }}</v-chip>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6" v-if="streamEngineVersionPayload.arch === 'x64'">
              <v-select
                v-model="streamEngineVersionPayload.os_ids"
                :items="supportedOperatingSystems"
                :rules="[rules.required]"
                label="Operating System"
                :item-text="(item) => item.name + '  ' + item.version"
                item-value="id"
                outlined
                small-chips
                multiple
                deletable-chips
                chips
                hide-details="auto"
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    item.name + ' ' + item.version
                  }}</v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col v-if="streamEngineVersionPayload.arch === 'arm64'" cols="6">
              <v-select
                v-model="streamEngineVersionPayload.jetpack_ids"
                hide-details="auto"
                label="Jetpack Versions"
                outlined
                :items="jetpackVersionList"
                :rules="[rules.required]"
                :item-text="
                  (item) =>
                    'L4T: ' +
                    item.l4t +
                    ' / ' +
                    'Jetpack Version: ' +
                    item.version
                "
                item-value="id"
                small-chips
                multiple
                deletable-chips
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    'Jetpack: ' + item.version
                  }}</v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ 'Jetpack: ' + item.version }}</span>
                          <v-spacer></v-spacer>
                          <v-chip small color="primary">{{
                            'L4T: ' + item.l4t
                          }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="streamEngineVersionPayload.version"
                :rules="[rules.required]"
                auto-select-first
                label="*Stream Engine Version"
                color="deep-purple"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="streamEngineVersionPayload.shasum"
                :rules="[rules.required]"
                color="deep-purple"
                label="*Shasum"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="streamEngineVersionPayload.arch === 'x64'">
              <v-autocomplete
                v-model="streamEngineVersionPayload.min_x86_client_version_id"
                :items="x86CordatusClients"
                item-text="version"
                item-value="id"
                auto-select-first
                :rules="[rules.required]"
                label="*Minimum Client(x86) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="streamEngineVersionPayload.arch === 'arm64'">
              <v-autocomplete
                v-model="
                  streamEngineVersionPayload.min_jetson_client_version_id
                "
                :items="jetsonCordatusClients"
                item-text="version"
                item-value="id"
                auto-select-first
                :rules="[rules.required]"
                label="*Minimum Client(arm64) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="editStreamEngineVersion()"
              :disabled="!form || loading"
              color="primary"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import { apiService } from '../../../services/api.service'

export default {
  computed: {
    ...mapGetters([
      'clientVersionList',
      'clientVersionTableLoading',
      'x86CordatusClients',
      'jetsonCordatusClients',
      'supportedOperatingSystems',
      'jetpackVersionList'
    ])
  },
  props: {
    showEditStreamEngineVersionDialog: { type: Boolean, default: false },
    streamEngine: {
      type: Object,
      default: () => null
    }
  },

  data: () => ({
    loading: false,
    form: true,
    streamEngineVersionPayload: {
      stream_engine_id: null,
      arch: '',
      version: '',
      min_x86_client_version_id: null,
      min_jetson_client_version_id: null,
      os_ids: [],
      jetpack_ids: [],
      shasum: null
    },
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),

  created () {
    this.getVersionList()
    this.copyStreamEngine()
  },

  methods: {
    async editStreamEngineVersion () {
      if (this.$refs.newStreamEngineVersionForm.validate()) {
        this.loading = true
        await apiService
          .put('admin/stream_engine_version', this.streamEngineVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Stream engine version edited successfully'
            })
            this.$emit('fetchStreamEngineVersions')
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('ERR', err.response)
            Swal.fire({
              icon: 'error',
              title: 'An error occurred while edit stream engine!',
              text: err.response.data.message
            })
          })
      }
    },
    getVersionList () {
      if (this.clientVersionList.length === 0) {
        this.$store.dispatch('getClientVersion')
      }

      if (this.supportedOperatingSystems.length === 0) {
        this.$store.dispatch('getSupportedOperatingSystem')
      }

      if (this.jetpackVersionList.length === 0) {
        this.$store.dispatch('getJetpackVersionList')
      }
    },
    copyStreamEngine () {
      this.streamEngineVersionPayload.stream_engine_id = this.streamEngine.id
      this.streamEngineVersionPayload.arch = this.streamEngine.arch
      this.streamEngineVersionPayload.version = this.streamEngine.version
      this.streamEngineVersionPayload.min_x86_client_version_id =
        this.streamEngine.min_x86_client_info?.id
      this.streamEngineVersionPayload.min_jetson_client_version_id =
        this.streamEngine.min_jetson_client_info?.id
      this.streamEngineVersionPayload.shasum = this.streamEngine.shasum

      this.streamEngineVersionPayload.os_ids =
        this.streamEngine.supported_os.map((item) => item.id)
      this.streamEngineVersionPayload.jetpack_ids =
        this.streamEngine.jetpack_versions.map((item) => item.id)
    },

    close () {
      this.$emit('closeEditStreamEngineVersion')
    }
  }
}
</script>

  <style>
</style>
