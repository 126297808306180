<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title color="rgba(0, 0, 0, 0)" flat>
          <span class="font-weight-regular">Stream Engine Versions</span>
          <v-divider class="ml-2 mr-2" vertical></v-divider>
          <v-btn @click="streamEngineList" icon>
            <v-icon dark>mdi-sync</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            class="mr-2"
            clearable
            dense
            hide-details
            label="Search stream version.."
            outlined
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-btn @click="newVersion()" outlined color="primary">
            <v-icon left> mdi-plus </v-icon>
            New Version
          </v-btn>
        </v-card-title>

        <v-card-text class="mb-0 pb-0 mx-0 px-0">
          <v-data-table
            :loading="streamEngineVersionLoading"
            :search="search"
            :headers="headers"
            :items="streamEngineVersionList"
            mobile-breakpoint="0"
            ref="devicesTable"
            sort-desc
            :items-per-page="20"
            :footer-props="{
              'items-per-page-options': [10, 20, 40, -1],
            }"
          >
            <template v-slot:[`item.-os`]="{ item }">
              <span v-if="item.os"> {{ item.os }} </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.version`]="{ item }">
              <span v-if="item.version"> {{ item.version }} </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.release_date`]="{ item }">
              <span v-if="item.release_date"> {{ item.release_date }} </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.min_jetson_client_info`]="{ item }">
              <span v-if="item.min_jetson_client_info.version">
                {{ item.min_jetson_client_info?.version }}
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.min_x86_client_info`]="{ item }">
              <span v-if="item.min_x86_client_info.version">
                {{ item.min_x86_client_info?.version }}
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.arch`]="{ item }">
              <v-chip
                v-if="item.arch"
                small
                dark
                :color="item.arch === 'x64' ? 'primary' : 'green darken-3'"
                >{{ item.arch }}</v-chip
              >
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.changelog`]="{ item }">
              <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="indigo" dark v-bind="attrs" v-on="on">
                    See Changelog
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <span>{{
                      item.changelog ? item.changelog : 'No changes'
                    }}</span>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>

            <template v-slot:[`item.supported_os`]="{ item }">
              <v-menu
                v-if="item.arch === 'x64'"
                :close-on-content-click="false"
                open-on-hover
                bottom
                offset-y
                :nudge-width="50"
                max-height="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    depressed
                    small
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    Supported OS List
                    <v-icon small>mdi-arrow-down</v-icon>
                  </v-btn>
                </template>

                <v-card class="pa-3">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                      small
                      color="primary"
                      v-for="(os, index) in item.supported_os"
                      :key="index"
                    >
                      {{ os.name }} {{ os.version }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
              </v-menu>

              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.jetpack_versions`]="{ item }">
              <v-menu
                v-if="item.arch === 'arm64'"
                :close-on-content-click="false"
                open-on-hover
                bottom
                offset-y
                :nudge-width="50"
                max-height="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green darken-4"
                    dark
                    depressed
                    small
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    Jetpack Versions
                    <v-icon small>mdi-arrow-down</v-icon>
                  </v-btn>
                </template>

                <v-card class="pa-3">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                      small
                      color="primary"
                      v-for="(os, index) in item.jetpack_versions"
                      :key="index"
                    >
                      Jetpack: {{ os.version }} L4T: {{ os.l4t }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
              </v-menu>

              <v-icon class="d-flex text-center" v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.user_added`]="{ item }">
              <span v-if="item.user_added">
                {{ item.user_added }}
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.notification_status`]="{ item }">
              <v-icon
                color="green"
                v-if="item.notification_status === 'Successful'"
              >
                mdi-check-bold
              </v-icon>
              <v-btn
                color="purple"
                dark
                small
                v-else-if="item.notification_status === 'Pending'"
                @click="notify_client(item)"
                >Notify</v-btn
              >
              <v-chip
                dark
                color="green"
                v-else-if="item.notification_status === 'Notifying'"
                small
              >
                <v-icon left class="slow-ani" small> mdi-cog </v-icon>
                <span>Notifying...</span>
              </v-chip>
              <v-chip
                small
                v-else
                dark
                :color="notificationChipColor(item.notification_status)"
              >
                {{ item.notification_status }}...
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <v-btn
                  v-for="(action, idx) in actionButtons"
                  :key="idx"
                  icon
                  @click="action.func(item)"
                >
                  <v-icon :color="action.color || 'black'">{{
                    action.icon
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <new-stream-engine-version
          v-if="showNewStreamEngineVersionDialog"
          :showStreamEngineVersionDialog="showNewStreamEngineVersionDialog"
          @closeNewClientVersionDialog="closeNewStreamEngineVersionDialog"
          @fetchStreamEngineVersions="fetchStreamEngines"
        ></new-stream-engine-version>

        <edit-stream-engine-version
          v-if="showEditStreamEngineVersionDialog"
          :showEditStreamEngineVersionDialog="showEditStreamEngineVersionDialog"
          :streamEngine="selectedStreamEngine"
          @closeEditStreamEngineVersion="
            showEditStreamEngineVersionDialog = false
          "
          @fetchStreamEngineVersions="fetchStreamEngines"
        ></edit-stream-engine-version>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import converter from '@/mixins/converter'
import get from 'lodash/get'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import EditStreamEngineVersion from './EditStreamEngineVersion.vue'
import NewStreamEngineVersion from './NewStreamEngineVersion.vue'
import { mapGetters } from 'vuex'

export default converter.extend({
  components: { NewStreamEngineVersion, EditStreamEngineVersion },
  computed: {
    ...mapGetters(['nonNotifiedStreamEngineVersionIds']),

    notificationChipColor: function () {
      return function (state) {
        switch (state) {
          case 'Failed':
            return 'red'
          default:
            return 'orange'
        }
      }
    }
  },
  name: 'streamEngineVersion',
  data () {
    return {
      streamEngineVersionLoading: false,
      showNewStreamEngineVersionDialog: false,
      showEditStreamEngineVersionDialog: false,
      selectedStreamEngine: {},
      search: '',
      streamEngineVersionList: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Architecture', value: 'arch', align: 'center' },
        { text: 'Version', value: 'version' },
        { text: 'Shasum', value: 'shasum' },
        { text: 'Jetpack Version', value: 'jetpack_versions', align: 'center' },
        { text: 'OS', value: 'supported_os', align: 'center' },
        {
          text: 'Min client(arm64) version',
          value: 'min_jetson_client_info',
          align: 'center'
        },
        {
          text: 'Min client(x64) version',
          value: 'min_x86_client_info',
          align: 'center'
        },
        {
          text: 'Release Date',
          value: 'release_date',
          align: 'center'
        },
        { text: 'User Added', value: 'user_added', align: 'center' },
        {
          text: 'Notified Clients',
          value: 'notification_status',
          align: 'center'
        },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      actionButtons: [
        {
          icon: 'mdi-circle-edit-outline',
          color: 'grey',
          func: this.editStreamEngineVersion
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.deleteStreamEngineVersion
        }
      ]
    }
  },
  mixins: [converter],
  mounted () {
    this.streamEngineList()
  },
  methods: {
    async notify_client (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `All Clients ${
          item.jetpack_versions.length > 0
            ? 'with assigned jetpack versions'
            : ''
        } which are connected to Cordatus will be notified about the changes. This process cannot be undone!`,
        icon: 'question',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const streamEngineVersionIds = [item.id]
            const notificationConfig = await this.$store.dispatch('notifyClients', {
              notifyStreamEngineVersion: true,
              nonNotifiedStreamEngineVersionIds: streamEngineVersionIds
            })
            notificationConfig.status = 'Notifying'
            await this.$store.dispatch('updateNotificationStatus', { body: notificationConfig })

            if (
              this.nonNotifiedStreamEngineVersionIds.length > 0 &&
              this.nonNotifiedStreamEngineVersionIds.includes(item.id)
            ) {
              this.$store.dispatch(
                'removeNonNotifiedStreamEngineVersion',
                item.id
              )
            }
            this.streamEngineList()
            Swal.fire('Success!', 'Started notifying process.', 'success')
          } catch (error) {
            console.log('Error while notifying clients: ', error.message)
            Swal.fire(
              'Warning!',
              'Notifying process cannot be started.',
              'warning'
            )
          }
        }
      })
    },
    async streamEngineList () {
      this.streamEngineVersionLoading = true
      await apiService
        .get('admin/stream_engine_version')
        .then((res) => {
          this.streamEngineVersionList = res.data.data
        })
        .catch((err) => {
          this.streamEngineVersionLoading = false
          Swal.fire({
            icon: 'error',
            title: 'Error getting stream engine version list',
            text: get(err, 'response.data.message', 'Something went wrong')
          })
        })
        .finally(() => (this.streamEngineVersionLoading = false))
    },

    deleteStreamEngineVersion (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `This "${item.version}" stream engine version will be delete.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .delete(`admin/stream_engine_version/${item.id}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Deleted',
                text: 'Stream engine version deleted successfully!'
              })
              this.streamEngineList()
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error while deleted stream engine version.',
                text: get(err, 'response.data.message', 'Something went wrong')
              })
            })
        }
      })
    },

    editStreamEngineVersion (item) {
      this.selectedStreamEngine = item
      this.showEditStreamEngineVersionDialog = true
    },

    newVersion () {
      this.showNewStreamEngineVersionDialog = true
    },
    closeNewStreamEngineVersionDialog () {
      this.showNewStreamEngineVersionDialog = false
    },
    fetchStreamEngines () {
      this.streamEngineList()
      this.showNewStreamEngineVersionDialog = false
    }
  }
})
</script>

<style>
.slow-ani {
  -webkit-animation: rotation 6s infinite linear;
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
